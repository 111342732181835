
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

.MuiPopover-paper {
  background-color: $color-robins-egg-blue !important;
  min-height: $quadruple-space;
  width: 200px;
  padding: $single-space;
  text-align: center;
  color: $color-black;
  font-size: $content-font-size-s;
  text-wrap: wrap;
  box-shadow: 0 0 $half-space 3px $color-mid-light-grey;

  .auth-link {
    color: $color-deep-black;
    font-weight: $content-font-weight-bold;
    border-bottom: 1px solid $color-deep-black;
  }

  .arrow-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-left: $single-space solid transparent;
    border-right: $single-space solid transparent;
    border-top: $single-half-space solid $color-robins-egg-blue;
    transform: translateX(-50%) translateY(100%);
  }
}

.MuiPaper-root {
  overflow: inherit !important;
}
