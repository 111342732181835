
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

.b-user-collections-list {
  @include respond-to(desktop) {
    padding: $triple-space $quadruple-space 0;
  }

  @include respond-to(tablet-down) {
    padding: $triple-space 0 0;
  }

  text-align: left;

  &:nth-of-type(3) .user-collections-list {
    margin-bottom: 125px;
  }

  .h3 {
    font-size: $content-font-size-xs;
    font-weight: $content-font-weight-bold;
    line-height: $content-line-height-xxsh;
    text-transform: uppercase;
    color: $color-dark-blue;
  }

  .user-collections-list {
    border-top: $border-width solid $color-light-grey;
  }

  .user-collections-list-item {
    position: relative;
    display: flex;
    border-bottom: $border-width solid $color-light-grey;
    padding: $single-space;
    text-align: left;
    color: $color-mid-grey;

    &:hover,
    &:focus {
      background-color: $color-lightest-grey;
    }
  }

  .collection-name {
    flex: 1;
    text-align: left;
  }

  .collections-list-item-button {
    width: 24px;
    height: 24px;
    margin-left: $single-space;
    border-radius: $border-radius-curved;
    color: $color-white;

    &.add-button {
      background-color: $color-light-grey;

      &:hover {
        background-color: $color-mid-grey;
      }
    }

    &.check-button {
      background-color: $color-dark-blue;

      &:hover {
        .ico::before {
          content: "\e9c7"; // the "remove" icon
        }
      }
    }
  }

  .link-to-collection {
    margin: 0 $single-space;
    color: $color-mid-grey;
  }

  .ico-view-eye {
    margin: $half-space 0 0 $half-space;
  }

  .ico-lock {
    margin-left: $half-space;
    padding: $half-space;
  }

  .ico-add,
  .ico-check,
  .ico-remove {
    font-size: $content-font-size-xxs;
    line-height: $content-line-height-s;
  }
}
