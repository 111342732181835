
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Add to collections
 */

.b-add-to-collections {
  @include respond-to(desktop) {
    display: flex;
    height: 100%;

    .design-wrapper {
      box-sizing: border-box;
      position: fixed;
      top: 0;
      right: 540px;
      width: 213px;
      height: 100%;
      border-right: 2px solid $color-light-grey;
      padding: $double-space;
      text-align: left;
    }

    .user-collections-wrapper {
      box-sizing: border-box;
      width: 538px;
      margin-left: 213px;
      padding-top: $quadruple-space;
    }

    .x-create-collection-wrapper {
      padding: $quadruple-space;
    }

    .b-modal-button-group {
      width: $modal-regular-width;
    }
  }

  @include respond-to(tablet-down) {
    padding: 0 $double-space;

    .design-wrapper {
      margin: $triple-space 0;
      text-align: center;
    }

    .design-image {
      width: $hextuple-space;
      height: $hextuple-space;
    }

    .b-modal-button-group {
      width: 100%;
    }
  }

  .design-name {
    @include ellipsis;

    box-sizing: border-box;
    margin: $single-space 0 0;
    font-size: $content-font-size-s;
    font-weight: $content-font-weight-bold;
    line-height: $content-line-height-s;

    .tag {
      position: relative;

      &::after {
        padding-right: $half-space;
        content: ",";
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  .user-collections-wrapper {
    text-align: center;

    .h2 {
      font-size: $base-font-size;
      line-height: $content-line-height-m;
      text-align: center;
    }
  }

  .b-modal-button-group {
    box-sizing: border-box;
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 0;
    border-top: 2px solid $color-light-grey;
    padding: $double-space;
    text-align: center;
    background-color: $color-white;
  }
}
