
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

/*
 * Modal Button Group // TODO: EN-2368 Update Button Group to handle the case for buttons within collection modal
 */

.b-modal-button-group {
  @include respond-to(desktop) {
    .btn:first-child {
      margin-right: $single-space;
    }
  }

  @include respond-to(tablet) {
    .btn {
      width: auto;
    }
  }

  @include respond-to(tablet-down) {
    .btn:first-child {
      margin-right: 4%;
    }

    .btn:first-child:nth-last-child(2),
    .btn:first-child:nth-last-child(2) ~ .btn {
      // if there are 2 button
      width: 48%;
    }

    .btn {
      font-size: $base-font-size;
      line-height: $base-line-height;
    }
  }

  clear: both;
  width: 100%;
  margin-bottom: $triple-space;
  white-space: nowrap;
  vertical-align: top;

  .b-form & {
    margin-top: $double-space;
  }

  .btn {
    box-sizing: border-box;
    min-width: 133px;
    padding: $single-space;
    white-space: normal;
    vertical-align: top;
  }
}
